<template>
    <div>
      <Header/>
      <div class="content_text">
        <!-- <router-link :to="{path:'../../'}">
          <img class="content_img_1" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_2_1" src="../../assets/task/time_icon.png">
        <div>{{task}}</div>
        <div class="rank_div">
          <!-- <router-link :to="{path:'/my/activation_record'}"> -->
          <img class="rank_icons" src="../../assets/task/menu_icon.png" @click="activation_record">
          <!-- </router-link> -->
        </div>
      </div>
      <div style="padding: 17px;">
          <div class="grace-forms">
            <div class="grace-form-items grace-border-bs">
              <div class="grace-form-label" style="line-height: 8vh;">{{title}}</div>
              <div class="grace-form-body grace-rows grace-flex-vtop">
                <input type="text" :placeholder="title_placeholder" v-model="read_title" class="input_add border-style-box">
              </div>
            </div>
          </div>
          <div class="grace-forms">
            <div class="grace-form-items grace-border-bs">
              <div class="grace-form-label" style="line-height: 30vh;">内容</div>
              <div class="grace-form-body grace-rows grace-flex-vtop">
                <textarea class="input_add border-style-box" v-model="read_content" style="line-height: 18px;min-height: 184px;height: auto;" :placeholder="text_placeholder"></textarea>
              </div>
            </div>
          </div>
          <div class="grace-form-items grace-border-bs">
            <div class="grace-form-label" style="line-height: 20vh;">照片</div>
            <div class="grace-form-body" style="justify-content: space-between; border: 0px;margin-top: 2px;">
                <div class="grace-add-list-items_s grace-add-list-btn">
                    <div class="grace-add-list-items_s grace-add-list-btn">
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :http-request="httpRequest"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="image" :src="image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
            </div>
          </div>
          <div class="center-form">
            <!-- <button style="margin: 0px; padding: 0px; width: 72px; height: 34px;"></button> -->
            <img src="../../assets/task/done_btn.png"  @click="save()" style="margin: 0px; padding: 0px; width: 72px; height: 34px;">
          </div>
          <div style="height:40px;width:100%;"></div>
      </div>
    </div>
</template>

<style>
.avatar-uploaders{
    width:100%;
    height:100%;
}
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .avatar {
    width: 111px;
    height: 111px;
    display: block;
  }
.center-form{
  margin: 20px;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.grace-add-list-btn-text{
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #999;
    width: 100%;  
}
.grace-add-list-btn-icon{
    font-size: 38px;
    height: 38px;
    line-height: 38px;
    margin-bottom: 9px;
    color: #999;
}
.grace-add-list-btn{
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.grace-add-list-items_s{
    width: 106px;
    height: 106px;
    background: #f6f7f8;
    border-radius: 4px;
}
::-webkit-input-placeholder{
    color:#ccc;
}
.input_add{
  color:#fff;
  width: 100%;
  padding: 3px 3px;
  font-size: 15px;
  border: none;
  background-color: rgb(196, 155, 108) !important;
   min-height: 1.4em;
}
.grace-form-body{
  padding: 10px 0!important;
  background: transparent!important;
  border: 1px solid hsla(0,0%,100%,.8);
  padding: 5px 8px;
  color: #fff;
  width: 336px;
  margin-left: 9px;
  overflow: hidden;
}
  .grace-form-label{
    text-align: left;
    width: 60px!important;
    color: #fff!important;
    font-size: 13px;
    line-height: 3.6;
    height: auto!important;
  }
  .grace-form-items{
    color: #222;
    margin-top: 15px;
    border: 0 solid hsla(0,0%,100%,0);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
  }
  .grace-forms{
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 58px;
  }
  .content_img_2_1{
    width: 50px;
    height: 48px;
    margin-right: 8px;
  }
  .rank_div{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
.rank_icons{
  height: 30px;
  margin-right: 8px;
  width: 40px;
}
</style>
<script>
import Header from "../Header";
import {apiCreateUserClockLogsNote,apiImageUpload} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      read_title:'',
      read_content:'',
      image:'',
      title:'',
      title_placeholder:'',
      text_placeholder:'',
      lang_id:0,
      task:'',
    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    this.lang_id = lang_id;
    if(lang_id == 1){
      this.title = this.$zh_ft('title');
      this.title_placeholder = this.$zh_ft('title_placeholder');
      this.text_placeholder = this.$zh_ft('text_placeholder');
      this.task = this.$zh_ft('task');
    }else{
      this.title = this.$zh_jt('title');
      this.title_placeholder = this.$zh_jt('title_placeholder');
      this.text_placeholder = this.$zh_jt('text_placeholder');
      this.task = this.$zh_jt('task');
    }
  },
  methods:{
    beforeAvatarUpload(file) {
        let lang_id = this.lang_id;
        let types = ['image/png', 'image/jpeg', 'image/jpg'];
        let isUploadFile = types.includes(file['type']);

        // const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isUploadFile) {
          if(lang_id == 1){
            this.$message.error('上傳頭像圖片只能是Image格式!');
          }else{
            this.$message.error('上传头像图片只能是Image格式!');
          }
          return false;
        }
        // if (!isLt2M) {
        //   if(lang_id == 1){
        //     this.$message.error('上傳頭像圖片大小不能超過 2MB!');
        //   }else{
        //     this.$message.error('上传头像图片大小不能超过 2MB!');
        //   }
        //   return false;
        // }
        

        let formData = new FormData();
        formData.append("file", file);
        this.formData = formData;
    
        // return isUploadFile && isLt2M;
        return isUploadFile;
      },
    httpRequest() {
        apiImageUpload(this.formData).then(res=>{
            if(res.code == 200){
                this.image = res.data.file_url;
            }
        })
    },
    save(){
      let id = this.$route.params.id;
      let lang_id = this.lang_id;
      if(!this.read_title){
        if(lang_id == 1){
          this.$message.error('請填寫標題');
        }else{
          this.$message.error('请填写标题');
        }
        return false;
      }
      if(!this.read_content){
        if(lang_id == 1){
          this.$message.error('請填寫内容');
        }else{
          this.$message.error('请填写内容');
        }
        return false;
      }
      if(!this.image){
        if(lang_id == 1){
          this.$message.error('請選擇圖片');
        }else{
          this.$message.error('请选择图片');
        }
        return false;
      }

      if(this.read_content.length < 10){
        if(lang_id == 1){
          this.$message.error('請填寫内容（10字以上）');
        }else{
          this.$message.error('请填写内容（10字以上）');
        }
        return false;
      }
      
      if(id && id>0){
        apiCreateUserClockLogsNote({
          clock_id:id,
          read_title:this.read_title,
          read_content:this.read_content,
          read_photo:this.image,
          lang_id:this.$store.state.lang_id
        }).then(res=>{
          if(res.code == 200){
            this.$message.success('添加成功');
            this.$router.push({path:'/task'});
          }else{
            this.$message.error(res.message);
          }
        }).catch(error=>{
          console.log(error);
        })
      }
    },
    activation_record(){
      this.$router.push({
        path:'/my/activation_record',
        query:{
          id:this.$route.params.id,
        }
      });
    }
  }
}
</script>
<style>
</style>